export default [
  {
    path: '/products',
    name: 'products',
    component: () => import('@/views/products/Index.vue'),
    meta: {
      pageTitle: 'List products',
      breadcrumb: [
        {
          text: 'Products',
        },
        {
          text: 'List products',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/products/create',
    name: 'create_product',
    component: () => import('@/views/products/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create product',
      breadcrumb: [
        {
          text: 'Products',
        },
        {
          text: 'Create product',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/:id',
    name: 'show-product',
    props: true,
    component: () => import('@/views/products/Show.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Show product',
      breadcrumb: [
        {
          text: 'Products',
        },
        {
          text: 'Show product',
          active: true,
        },
      ],
    },
  },
  {
    path: '/products/edit/:id',
    name: 'edit-product',
    props: true,
    component: () => import('@/views/products/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Update product',
      breadcrumb: [
        {
          text: 'Products',
        },
        {
          text: 'Update product',
          active: true,
        },
      ],
    },
  },
]
