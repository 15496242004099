export default [
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/customers/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Customers',
      breadcrumb: [
        {
          text: 'Customers',
        },
        {
          text: 'customers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/:id',
    name: 'show-customer',
    props: true,
    component: () => import('@/views/customers/Show.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Show customer',
      breadcrumb: [
        {
          text: 'Customers',
        },
        {
          text: 'Show customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/create',
    name: 'create_customer',
    component: () => import('@/views/customers/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create a new customer',
      breadcrumb: [
        {
          text: 'Customers',
        },
        {
          text: 'create customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/edit/:id',
    name: 'edit-customer',
    props: true,
    component: () => import('@/views/customers/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Update customer',
      breadcrumb: [
        {
          text: 'Customers',
        },
        {
          text: 'Update supplier',
          active: true,
        },
      ],
    },
  },
]
