import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import createPersistedState from 'vuex-persistedstate'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {
      id: '',
      username: '',
    },
    role: '',
    token: '',
    isAuthenticated: false,
    isLoading: false,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('token')) {
        state.token = localStorage.getItem('token')
        state.isAuthenticated = true
      } else {
        state.token = ''
        state.isAuthenticated = false
      }
    },
    updateStorage(state, { token }) {
      state.token = token
      state.isAuthenticated = true
    },
    destroyToken(state) {
      state.user = {}
      state.token = ''
      state.isAuthenticated = false
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setUser(state, user) {
      state.user = user
    },
    setToken(state, token) {
      state.token = token
      state.isAuthenticated = true
    },
    setRole(state, role) {
      state.role = role
    },
    removeToken(state) {
      state.role = ''
      state.token = ''
      state.isAuthenticated = false
    },
  },
  getters: {
    loggedIn(state) {
      return state.token != null
    },
  },
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
  plugins: [createPersistedState()],

})
