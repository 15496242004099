export default [
  {
    path: '/stocks/in-way-to-tunisia',
    name: 'in-way-to-tunisia',
    component: () => import('@/views/stocks/InWayToTunisia.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'In way to Tunisia',
      breadcrumb: [
        {
          text: 'Stock',
        },
        {
          text: 'In way to Tunisia',
          active: true,
        },
      ],
    },
  },

  {
    path: '/stocks/in-way-to-brazil',
    name: 'in-way-to-brazil',
    component: () => import('@/views/stocks/InWayToBrazil.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'In way to Brazil',
      breadcrumb: [
        {
          text: 'Stock',
        },
        {
          text: 'In way to Brazil',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stocks/received-in-brazil',
    name: 'received-in-brazil',
    component: () => import('@/views/stocks/ReceivedInBrazil.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Received in Brazil',
      breadcrumb: [
        {
          text: 'Stock',
        },
        {
          text: 'Received in Brazil',
          active: true,
        },
      ],
    },
  },
  {
    path: '/stocks/received-in-tunisia',
    name: 'received-in-tunisia',
    component: () => import('@/views/stocks/ReceivedInTunisia.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Received in Tunisia',
      breadcrumb: [
        {
          text: 'Stock',
        },
        {
          text: 'Received in Tunisia',
          active: true,
        },
      ],
    },
  },

]
