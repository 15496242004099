export default [
  {
    path: '/delivery-notes',
    name: 'delivery-notes',
    component: () => import('@/views/deliveryNote/Index.vue'),
    meta: {
      pageTitle: 'List delivery notes',
      breadcrumb: [
        {
          text: 'delivery notes',
        },
        {
          text: 'List delivery notes',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/delivery-notes/create',
    name: 'create-delivery-note',
    component: () => import('@/views/deliveryNote/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create delivery note',
      breadcrumb: [
        {
          text: 'delivery note',
        },
        {
          text: 'Create delivery note',
          active: true,
        },
      ],
    },
  },
  {
    path: '/delivery-notes/:id',
    name: 'show-delivery-note',
    props: true,
    component: () => import('@/views/deliveryNote/Show.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Show delivery note',
      breadcrumb: [
        {
          text: 'delivery note',
        },
        {
          text: 'Show delivery note',
          active: true,
        },
      ],
    },
  },

  {
    path: '/delivery-notes/edit/:id',
    name: 'edit-delivery-note',
    props: true,
    component: () => import('@/views/deliveryNote/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Update delivery note',
      breadcrumb: [
        {
          text: 'delivery note',
        },
        {
          text: 'Update delivery note',
          active: true,
        },
      ],
    },
  },
]
