import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import products from './routes/products'
import suppliers from './routes/suppliers'
import orders from './routes/orders'
import invoices from './routes/invoices'
import purchaseInvoices from './routes/purchaseInvoices'
import customers from './routes/customers'
import deliveryNotes from './routes/deliveryNotes'
import stocks from './routes/stocks'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        requireLogin: true,
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
      meta: {
        pageTitle: 'dashboard',
        breadcrumb: [
          {
            text: 'dashboard',
            active: true,
          },
        ],
        requireLogin: true,
      },
    },
    {
      path: '/aac',
      name: 'aac',
      component: () => import('@/views/files/AAC.vue'),
      meta: {
        pageTitle: 'aac',
        breadcrumb: [
          {
            text: 'aac',
            active: true,
          },
        ],
        requireLogin: true,
      },
      beforeEnter: (to, from, next) => {
        const password = prompt('password')
        if (password === 'aac') {
          next()
        }
      },
    },
    ...suppliers,
    ...products,
    ...orders,
    ...invoices,
    ...purchaseInvoices,
    ...customers,
    ...deliveryNotes,
    ...stocks,
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requireLogin) && store.state.isAuthenticated === false) {
    next({ name: 'login' })
  } else {
    next()
  }
})
export default router
