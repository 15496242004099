export default [
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/invoices/Index.vue'),
    meta: {
      pageTitle: 'List invoices',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'List invoices',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/invoices/create/:id',
    name: 'create_invoice',
    component: () => import('@/views/invoices/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create invoice',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'Create invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoice/create/',
    name: 'create_invoices_man',
    component: () => import('@/views/invoices/CreateMan.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create invoice',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'Create invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoices/:id',
    name: 'show-invoice',
    props: true,
    component: () => import('@/views/invoices/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/proforma-invoices/:id',
    name: 'show-proforma-invoice',
    props: true,
    component: () => import('@/views/invoices/ShowProforma.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/invoices/edit/:id',
    name: 'edit-invoice',
    props: true,
    component: () => import('@/views/invoices/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Update invoice',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'Update invoice',
          active: true,
        },
      ],
    },
  },

  {
    path: '/show-paking-list/:id',
    name: 'show-paking-list',
    props: true,
    component: () => import('@/views/invoices/ShowPaking.vue'),
    meta: {
      requireLogin: true,

    },
  },
]
