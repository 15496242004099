export default [
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/orders/Index.vue'),
    meta: {
      pageTitle: 'List orders',
      breadcrumb: [
        {
          text: 'Orders',
        },
        {
          text: 'List orders',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/orders/create',
    name: 'create_order',
    component: () => import('@/views/orders/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create order',
      breadcrumb: [
        {
          text: 'Orders',
        },
        {
          text: 'Create order',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/:id',
    name: 'show-order',
    props: true,
    component: () => import('@/views/orders/Show.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Show order',
      breadcrumb: [
        {
          text: 'Orders',
        },
        {
          text: 'Show order',
          active: true,
        },
      ],
    },
  },

  {
    path: '/orders/invoice/:id',
    name: 'show-order-invoice',
    props: true,
    component: () => import('@/views/orders/Invoice.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Show purchase invoice',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'Show invoice',
          active: true,
        },
      ],
    },
  },

  {
    path: '/orders/edit/:id',
    name: 'edit-order',
    props: true,
    component: () => import('@/views/orders/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Update order',
      breadcrumb: [
        {
          text: 'Orders',
        },
        {
          text: 'Update order',
          active: true,
        },
      ],
    },
  },
]
