export default [
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('@/views/suppliers/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'suppliers',
      breadcrumb: [
        {
          text: 'suppliers',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suppliers/:id',
    name: 'show-supplier',
    props: true,
    component: () => import('@/views/suppliers/Show.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Show supplier',
      breadcrumb: [
        {
          text: 'supplier',
        },
        {
          text: 'Show supplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suppliers/create',
    name: 'create_supplier',
    component: () => import('@/views/suppliers/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create a new supplier',
      breadcrumb: [
        {
          text: 'create supplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/suppliers/edit/:id',
    name: 'edit-supplier',
    props: true,
    component: () => import('@/views/suppliers/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier supplier',
      breadcrumb: [
        {
          text: 'supplier',
        },
        {
          text: 'Modifier supplier',
          active: true,
        },
      ],
    },
  },
]
