export default [
  {
    path: '/purchase-invoices',
    name: 'purchase_invoices',
    component: () => import('@/views/purchaseInvoices/Index.vue'),
    meta: {
      pageTitle: 'List purchase invoices',
      breadcrumb: [
        {
          text: 'Purchase invoices',
        },
        {
          text: 'List purchase invoices',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/purchase-invoices/create/:id',
    name: 'create_purchase_invoices',
    component: () => import('@/views/purchaseInvoices/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create invoice',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'Create invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-invoice/create/',
    name: 'create_purchase_invoices_man',
    component: () => import('@/views/purchaseInvoices/CreateMan.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Create invoice',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'Create invoice',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-invoices/:id',
    name: 'show-purchase_invoices',
    props: true,
    component: () => import('@/views/purchaseInvoices/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/purchase-invoices/edit/:id',
    name: 'edit-purchase_invoices',
    props: true,
    component: () => import('@/views/purchaseInvoices/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Update invoice',
      breadcrumb: [
        {
          text: 'Invoices',
        },
        {
          text: 'Update invoice',
          active: true,
        },
      ],
    },
  },
]
